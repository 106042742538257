<template>
  <div class="face-features">
    <img v-if="isSkinPicture" :src="chosenSkin" class="face-features__img" alt="skin-image" />
    <div v-else>
      <img
        :src="chosenHeadHairColor"
        class="face-features__img face-features__img-hair"
        alt="hair-image"
      />
      <img
        :src="chosenEyesColor"
        class="face-features__img face-features__img-eyes"
        alt="eyes-image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaceFeatures',
  props: {
    gender: {
      type: String,
      required: true
    },
    skin: {
      type: String,
      default: null
    },
    hair: {
      type: String,
      default: null
    },
    eyes: {
      type: String,
      default: null
    }
  },
  computed: {
    isSkinPicture() {
      return this.skin !== null;
    },
    chosenSkin() {
      const color = this.skin || 'white';

      return require(`@/modules/questionnaire/assets/images/face-features/${this.genderDirectory}/skin-color/${color}.svg`);
    },
    chosenHeadHairColor() {
      const color = this.hair || 'light_blonde';

      return require(`@/modules/questionnaire/assets/images/face-features/${this.genderDirectory}/head-hair-color/${color}.svg`);
    },
    chosenEyesColor() {
      const color = this.eyes || 'blue';

      return require(`@/modules/questionnaire/assets/images/face-features/${this.genderDirectory}/eyes-color/${color}.svg`);
    },
    genderDirectory() {
      return this.gender === 'other' ? 'female' : this.gender;
    }
  }
};
</script>

<style lang="scss" scoped>
.face-features {
  position: relative;

  &__img {
    position: absolute;
    width: 100%;
  }

  &__img-hair {
    z-index: 1;
  }

  &__img-eyes {
    z-index: 2;
  }
}
</style>
